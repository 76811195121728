body {
  line-height: 1.1;
  background-color: #444343;
}

nav {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10000;
  display: block;
}

nav a {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.red-button {
  background-color: #af4c4c; /* Green */
}

.pull-right {
  float: right;
}

#map{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.leaflet-container {
  height: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-tooltip {
  position: absolute;
  padding: 0px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  color: white;
  box-shadow: none;
  line-height: 1;
}

.leaflet-tooltip-bottom::before {
  border-bottom-color: transparent;
}

.map-icon-red {
  color: red;
}

.map-icon-green {
  color: green;
}

.tooltip-occupied {
  background-color: red;
}

.tooltip-free {
  background-color: green;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

form {
  width: 100%;
}

input {
  font-size: 30px;
  display: block;
  width: 100%;
  box-sizing:border-box;
  margin: 10px 0px;
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  border-radius: 2px;
}

.contentWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
}

.error {
  font-size: 30px;
  border: 1px solid red;
  background-color: #ff6c6c;
  border-radius: 2px;
  padding: 0px 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.info {
  font-size: 30px;
  border: 1px solid #000000;
  background-color: #c1c1c1;
  border-radius: 2px;
  padding: 0px 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.error::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f071";
  padding-right: 10px;
}

.position-table {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  font-size: 1.2em;
}

.App .position-table {
  padding-top: 10px !important;
}

table{
  background-color: #c1c1c1;
  font-size: 1em;
  width: inherit;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  table {
    width: 100%;
  }

  .logout {
    display: none;
  }
}

table, td, tr, th {
  border: 1px solid black;
  padding-left: 10px;
  padding-right: 10px;
  border-collapse:collapse;
}

thead {
  background-color: #737373;
}

.bg-free, .bg-free a {
  color: rgb(0, 161, 0);
}

.bg-occupied, .bg-occupied a{
  /*color: white;*/
  color: #bb0000;
}

.bg-nodata, .bg-nodata a{
  /*color: white;*/
  color: #bb0000;
  text-decoration: line-through;
}

td a {
  text-decoration: none;
  color: #000;
}

td .fa{
  font-size: 0.8em;
  padding-bottom: 2px;
}

.message {
  margin-top: 50px;
}

.datenschutz, .datenschutz a {
  color: white;
}

.call-col {
  padding-left: 5px !important;
  padding-right: 5px !important;
  white-space: nowrap;
}
